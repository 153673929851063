<template>
  <div>
    <div style="line-height: 2rem;" class="flex-def">
      <div>累计进账总额 {{pool.total}} 元</div>
      <div style="margin-left: 2rem">当前未分成金额 {{pool.cur_wait}} 元</div>
    </div>
    <el-tabs>
      <el-tab-pane label="进账明细">
        <PoolGetLog></PoolGetLog>
      </el-tab-pane>
      <el-tab-pane label="分成记录">
        <PoolOutLog></PoolOutLog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import PoolGetLog from "@/view/shop/plugin/operation-center/PoolGetLog";
import PoolOutLog from "@/view/shop/plugin/operation-center/PoolOutLog";
export default {
  components: {PoolOutLog, PoolGetLog},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      pool:{
        total:0,
        cur_wait:0,
      }
    }
  },
  mounted() {
    this.$u.api.shop.plugin.operationCenter.pool().then(res=>{
      this.pool = res;
    })
  },
  methods: {

  },
}
</script>

<style scoped>

</style>